<template>
  <a-modal :class="$style.container" v-model="isShow" title="发货" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="物流公司" prop="com">
          <a-select v-model="form.com" style="width:250px">
            <a-select-option v-for="(item, index) in express" :key="index" :value="item.code">{{ item.name }}</a-select-option>
          </a-select>
      </a-form-model-item>
       <a-form-model-item label="物流单号" prop="num">
          <a-input v-model="form.num" placeholder="请输入物流单号" style="width:250px"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      orderNo: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        com: '',
        expressName: '',
        num: ''
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        com: [
          { required: true, message: '请输入物流公司', trigger: 'blur' }
        ],
        num: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
          { min: 1, max: 30, message: '请输入1-30位字符', trigger: 'blur' }
        ]
      },
      express: []
    }
  },
  created () {
    this.getExpress()
  },
  computed: {},
  methods: {
    init (item) {
      this.orderNo = item.orderNo
    },
    // 物流公司
    async getExpress () {
      const res = await this.$store.dispatch('http', {
        api: 'getDictItems',
        query: {
          code: 'express'
        }
      })
      if (res) {
        const that = this
        res.map(item => {
          that.express.push({ code: item.code, name: item.name })
        })
      }
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        com: '',
        expressName: '',
        num: ''
      }
    },
    // 提交保存.
    async determine () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.express.map(item => {
            if (item.code === this.form.com) {
              this.form.expressName = item.name
            }
          })
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'oderDeliver'
          let query = {
            orderNo: this.orderNo
          }
          await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (this.form.id ? '' : '') + '操作成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
