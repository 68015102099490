<!--
 * @Descripttion:弹窗口 查看 商品的取货记录
 * @version:
 * @Author: tianmeng
 * @Date: 2022-1-17 11:28:34
 * @LastEditors: tianmeng
 * @LastEditTime: 2022-1-17 11:28:34
-->
<template>
  <a-modal width="1100px" :footer="null"  v-model="isShow" title="物流信息">
    <a-table :data-source="list"
             :rowKey="record => record.id"
             :columns="columns"
             :pagination="false"
             bordered>
      <div slot="action1" slot-scope="scope">
        <div style="color:#4f9efa;">{{ scope.time }}</div>
      </div>
      <div slot="action2" slot-scope="scope">
        <div>{{ scope.context }}</div>
      </div>
    </a-table>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
    </a-modal>
    <div slot="footer"></div>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      disabledStatus: false, // 是否禁用
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      columns: [
        { title: '时间', width: 220, key: '', scopedSlots: { customRender: 'action1' } },
        { title: '信息', width: 780, key: '', scopedSlots: { customRender: 'action2' } }
      ],
      list: []
    }
  },
  methods: {
    init (item) {
      this.list = []
      this.getInfo(item.shipping.expressCode, item.shipping.expressNo)
    },
    async getInfo (expressCode, expressNo) {
      const res = await this.$store.dispatch('http', {
        api: 'getOrderLogistislItem',
        params: {
          com: expressCode,
          num: expressNo
        }
      })
      console.log(res)
      this.list = res.data
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
    },
    handleCancel (e) {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
