<template>
  <div style="width:1600px; overflow:scroll;">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>

    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.orderNo" placeholder="订单编号"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.userName" placeholder="账号"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="((e)=>{queryData()})">查询</a-button>
        <a-button style="margin-left:20px;" @click="resetForm">重置</a-button>
        <a-button style="margin-left:20px;" @click="exportOrder" type="primary">订单导出</a-button>
      </a-form-item>
    </a-form>
    <br/>
    <!--标签-->
    <div>
      <a-tabs default-active-key="" @change="callback" :activeKey="activeKey">
        <a-tab-pane v-for="item in orderStatusTags" :key="item.tagCode" :tab="item.tagName"/>
      </a-tabs>
    </div>
    <div class="table-layout2 flex-row">
      <div style="width:450px;" class="table-cell">商品信息</div>
      <div style="width:150px;" class="table-cell">金额</div>
      <div style="width:100px;" class="table-cell">状态</div>
      <div style="width:240px;" class="table-cell">买家收货信息</div>
      <div style="width:200px;" class="table-cell">物流信息</div>
      <div style="width:260px;" class="table-cell">时间</div>
      <div style="width:200px;" class="table-cell">操作</div>
    </div>
    <div class="table-layout" v-for="(item,index) in list" :key="index">
      <div class="flex-row">
        <div style="flex:1;" class="table-cell-title">
          <span>订单编号:{{ item.orderNo }}</span>
          <span style="margin-left: 50px">创建时间:{{ item.gmtCreate }}</span>
        </div>
      </div>
      <div class="flex-row">
        <!-- 商品信息-->
        <div style="width:450px;" class="table-cell2">
          <a-row v-for="(sku,index) in item.orderItems" :key="index">
            <a  @click="zoom(sku.imgUrl)">
              <a-avatar shape="square"
                    style="width:84px;height:84px;float: left;"
                    :src="sku.imgUrl" />
            </a>
            <div style="margin-top: 2px;margin-left: 90px;">{{ sku.productName }}</div>
            <div style="margin-top: 2px;margin-left: 90px;">{{ sku.spec }}</div>
            <div style="margin-top: 2px;margin-left: 90px;">SKU：{{ sku.productId }}
              <span style="margin-left: 210px;color: red">{{ sku.price }}</span> * {{ sku.qty }}
            </div>
            <div style="margin-top: 2px;margin-left: 90px;">SPU：{{ sku.goodsId }}</div>
            <hr v-if="index != item.orderItems.length-1" style="height:1px;border:none;border-top:1px solid #DCDFE6;" />
          </a-row>
        </div>
        <!-- 金额-->
        <div style="width:150px;" class="table-cell2">
          <div style="margin-top: 2px">总金额:<span style="color: red;margin-left: 12px">{{ item.totalPrice }}</span></div>
          <div>实付金额:<span style="color: red">{{ item.accountPad }}</span></div>
          <div></div>
          <div></div>
        </div>
        <!-- 状态-->
        <div style="width:100px;" class="table-cell2">
          <div style="color: #4f9efa;text-align: center">{{ item.orderStatus.name}}</div>
        </div>
        <!-- 买家收货信息-->
        <div style="width:240px;" class="table-cell2">
          <div style="margin-top: 2px" v-if="item.orderUser.tel!=''|| item.orderUser.userName!=''">
            账号：{{ item.orderUser.tel }} <span
            style="margin-left: 10px">{{ item.orderUser.userName }}</span></div>
          <div v-if="item.deliveryType=== 'fetch'">自提</div>
          <div v-if="item.orderAddress!=null && item.deliveryType === 'express'">
            <div>收货人：{{ item.orderAddress.addresseeName }} {{ item.orderAddress.addresseeTel }}</div>
            <div>收货地址：{{ item | formatAddress }}</div>
            <div>详细地址：{{ item.orderAddress.address }}</div>
          </div>
        </div>
        <!-- 物流信息-->
        <div style="width:200px;" class="table-cell2">
          <!-- <div v-if="item.deliverStatus==='outbound'||item.deliverStatus==='waitDeliver'">预计发货时间:</div>
          <div v-if="item.deliverStatus==='outbound'||item.deliverStatus==='waitDeliver'">{{item.gmtDeliveryStart}}-{{item.gmtDeliveryEnd}}</div> -->
          <div v-if="item.deliveryType === 'express' && item.shipping!=null">快递公司：{{ item.shipping.expressName }}</div>
          <div v-if="item.deliveryType === 'express' && item.shipping!=null">运单号：{{ item.shipping.expressNo }}</div>
        </div>
        <!-- 时间-->
        <div style="width:260px;" class="table-cell2">
          <div style="margin-top: 2px">下单时间：{{ item.gmtCreate }}</div>
          <div v-if="item.gmtPay!=null">支付时间：{{ item.gmtPay }}</div>
          <div v-if="item.gmtDeliver!=null && item.deliveryType==='express'">发货时间：{{ item.gmtDeliver }}</div>
          <div v-if="item.gmtDeliver!=null && item.deliveryType==='fetch'">自提时间：{{ item.gmtDeliver }}</div>
          <div v-if="item.gmtComplete!=null">完成时间：{{ item.gmtComplete }}</div>
        </div>
        <!-- 操作-->
        <div style="width:200px;" class="table-cell2">
          <div style="margin-top: 2px;text-align: center;font-size: 15px">
            <a @click="handleViewOrder(item.orderNo)">查看</a>
            <a v-if="item.deliverStatus==='waitDeliver' && item.deliveryType==='express' && item.status !=='refund'" style="margin-left: 20px;text-align: center"
              @click="$refs.dialog2.init(item);$refs.dialog2.isShow = true">发货</a>
            <a v-if="(item.deliverStatus==='deliver' || item.status==='complete') && item.deliveryType==='express'" style="margin-left: 20px;text-align: center"
              @click="$refs.dialog3.init(item);$refs.dialog3.isShow = true">物流信息</a>
            <a v-if="(item.deliverStatus==='deliver' || item.status==='complete') && item.deliveryType==='fetch'" style="margin-left: 20px;text-align: center"
              @click="$refs.dialog4.init(item);$refs.dialog4.isShow = true">取货记录</a>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="goodsImag"/>
    </a-modal>
    <deliver ref="dialog2" @save="queryData"></deliver>
    <logistics-item ref="dialog3" @save="queryData"></logistics-item>
    <fetch-item ref="dialog4" @save="queryData"></fetch-item>
    <br/>
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
  </div>
</template>

<script>
import { getQueryString } from '@/utils'
import store from '@/store'
import Breadcrumb from '@/components/breadcrumb.vue'
import Deliver from './dialog/deliver.vue'
import FetchItem from './dialog/fetchItem.vue'
import LogisticsItem from './dialog/logisticsItem.vue'

export default {
  components: {
    Breadcrumb,
    Deliver,
    FetchItem,
    LogisticsItem
  },
  data () {
    return {
      form2: {
        status: ''
      },
      activeKey: '',
      orderStatusTags: [
        {
          tagCode: '',
          tagName: '全部'
        },
        {
          tagName: '待付款',
          tagCode: 'waitPay'
        },
        {
          tagName: '待发货',
          tagCode: 'waitDeliver'
        },
        {
          tagName: '已发货',
          tagCode: 'deliver'
        },
        {
          tagName: '已完成',
          tagCode: 'complete'
        },
        {
          tagName: '已关闭',
          tagCode: 'close'
        }
      ],
      breadList: [
        {
          name: '订单管理',
          url: ''
        }
      ],
      visible: false,
      goodsImag: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      isQueryLoading: false,
      form: {
        orderNo: '',
        userName: ''
      },
      list: [],
      total: 0,
      pageSize: 10,
      current: 1
    }
  },
  filters: {
    formatAddress (v) {
      return v.orderAddress.provinceName + ' ' + v.orderAddress.cityName + ' ' + v.orderAddress.districtName
    }
  },
  created () {
    this.queryData()
  },
  methods: {
    handleViewOrder (orderNo) {
      console.log('查看详情：', orderNo)
      this.$router.push({
        path: '/order/orderDetail',
        query: { orderNo: orderNo }
      })
    },
    callback (key) {
      this.form.status = key
      this.activeKey = key
      this.queryData()
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'orderList',
        params: {
          ...this.form,
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      console.log(res, '表格数据')

      this.list = res.records
      this.total = res.total
      this.$forceUpdate()
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.del(id)
    },
    cancel (values) {
      console.log(values)
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.loadList()
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(event, pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
      this.queryList()
    },
    handleCancel (e) {
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.goodsImag = scope
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: '',
        code: ''
      }
      this.queryData()
    },
    async exportOrder () {
      let that = this
      const tokenType = getQueryString('tokenType') || store.state.user.tokenType
      const tokenValue = getQueryString('tokenValue') || store.state.user.tokenValue
      const currentTenantId = getQueryString('currentTenantId') || store.state.user.currentTenantId
      axios({
        method: 'get',
        responseType: 'blob',
        url:
            process.env.VUE_APP_GATEWAY + '/order/manage/order/export',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: tokenType + ' ' + tokenValue,
          tenantId: currentTenantId
        },
        params: this.form
      })
        .then(function (res) {
          if (res.data.type.includes('application/json')) {
            const reader = new FileReader()
            reader.onload = function (event) {
              console.log('event: ', event)
              const message = JSON.parse(event.target.result).msg
              that.$store.dispatch('showToast', {
                type: 'error',
                message: message
              })
            }
            reader.readAsText(res.data)
          } else {
            that.$store.dispatch('showToast', { message: '操作成功~' })
            const blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            })
            const fileName = res.headers['content-disposition']
              .split(';')[1]
              .split('filename=')[1]
            const eleLink = document.createElement('a')
            eleLink.download = decodeURIComponent(fileName)
            eleLink.style.display = 'none'
            eleLink.href = URL.createObjectURL(blob)
            // 触发点击
            document.body.appendChild(eleLink)
            eleLink.click()
            document.body.removeChild(eleLink)
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}

.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}

.font-small {
  font-size: 14px;
  color: #606266;
}

.table-layout {
  margin-top: 20px;
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
}

.table-layout2 {
  //background: #dde1e5;
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  font-weight: bold;
}

.table-cell2 {
  // height: auto;
  padding: 10px;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  //padding: 10px;
  font-size: 12px;
  color: #606266;
  //text-align: center;
  overflow: hidden;
}

.table-cell {
  height: 60px;
  line-height: 40px;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  font-size: 14px;
  color: #606266;
  //text-align: center;
  overflow: hidden;
}

.table-cell-title {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
  background: #F2F6FC;
  //text-align: center;
  font-size: 14px;
  color: #303133;
}
</style>
