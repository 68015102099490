<!--
 * @Descripttion:弹窗口 查看 商品的取货记录
 * @version:
 * @Author: tianmeng
 * @Date: 2022-1-17 11:28:34
 * @LastEditors: tianmeng
 * @LastEditTime: 2022-1-17 11:28:34
-->
<template>
  <a-modal width="1200px" :footer="null"  v-model="isShow" title="取货记录">
    <a-table :data-source="list"
             :rowKey="record => record.id"
             :columns="columns"
             :pagination="false"
             bordered>
      <div slot="action1" slot-scope="scope">
        <div style="color:#4f9efa;">{{ scope.idOOrder }}</div>
      </div>
      <div slot="action2" slot-scope="scope">
        <div>{{scope.fetchCode}}</div>
      </div>
      <div slot="action3" slot-scope="scope">
        <div>{{scope.fetch === 0 ? '未取货' : '已取货'}}</div>
      </div>
      <div slot="action4" slot-scope="scope">
        <div>{{scope.productName}}</div>
        <div>{{scope.spec}}</div>
      </div>
      <div slot="action5" slot-scope="scope">
        <div>{{scope.deptName}}</div>
        <div v-if="scope.deptCode != ''">驿站编号：{{scope.deptCode}}</div>
        <div v-if="scope.shelfCode != ''">机柜编号：{{scope.shelfCode}}</div>
      </div>
      <div slot="action6" slot-scope="scope">
        <span v-if="scope.gmtFetch != null">
          <div>取货时间：</div>
          <div>{{scope.gmtFetch}}</div>
        </span>
      </div>
    </a-table>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
    </a-modal>
    <div slot="footer"></div>
  </a-modal>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      disabledStatus: false, // 是否禁用
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      columns: [
        { title: '订单编号', width: 140, key: '', scopedSlots: { customRender: 'action1' } },
        { title: '取货码', width: 150, key: '', scopedSlots: { customRender: 'action2' } },
        { title: '状态', width: 80, key: '', scopedSlots: { customRender: 'action3' } },
        { title: '商品信息', width: 230, key: '', scopedSlots: { customRender: 'action4' } },
        { title: '数量', width: 80, key: 'qty', dataIndex: 'qty' },
        { title: '驿站', width: 220, key: '', scopedSlots: { customRender: 'action5' } },
        { title: '时间', width: 230, key: '', scopedSlots: { customRender: 'action6' } }
      ],
      list: []
    }
  },
  methods: {
    init (item) {
      this.list = []
      this.getInfo(item.orderNo)
    },
    async getInfo (orderNo) {
      const res = await this.$store.dispatch('http', {
        api: 'getOrderFetchItem',
        query: { orderNo: orderNo }
      })
      this.list = res
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
    },
    handleCancel (e) {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
